<template>
  <div class="thank-you">
    <h1>Thank you</h1>
    <el-button type="primary"
      ><a :href="`/${this.customer}`">Homepage</a></el-button
    >
  </div>
</template>

<script>
  export default {
    name: 'Thank you',
    data() {
      return {
        customer: location.pathname.split('/')[1],
      }
    },
  }
</script>

<style scoped>
  h1 {
    margin: 0 0 10px;
  }
  a {
    text-decoration: none;
    text-transform: uppercase;
  }

  div.thank-you {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 80vh;
    width: 100%;
  }
</style>
